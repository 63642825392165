<template>
  <div>
    <heade></heade>
    <div class="kc">
      <div class="kc-top">
        <el-breadcrumb separator="/">
  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>课程</el-breadcrumb-item>
  <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item>
  <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
</el-breadcrumb>
        <h2 style="font-weight: bold; font-size: 30px;margin-top:20px;">
          在线课程<span
            style="font-weight: 400; font-size: 16px; margin-left: 20px"
            >实 验 / 实 训 / 虚 仿 等 运 行 在 其 中 的 课 程</span
          >
        </h2>
      </div>
      <div class="tab">
        <div style="margin-right: 20px; color: #9096a3; width: 40px">方向</div>
        <ul class="kc-ul">
          <li
            :class="cur == '全部'? 'addBg' : ''"
           
            @click="clickFx('全部', '全部')"
          >
           全部
          </li>
          <li
            :class="cur == index ? 'addBg' : ''"
            v-for="(item, index) in fx"
            :key="index"
            @click="clickFx(index, item.label)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="tab">
        <div style="margin-right: 20px; color: #9096a3; width: 40px">子类</div>
        <ul class="kc-ul">
          <li
            :class="curZl == '全部'? 'addBg' : ''"
           
            @click="clickZl('全部', '全部')"
          >
           全部
          </li>
          <li
            :class="curZl == index ? 'addBg' : ''"
            v-for="(item, index) in zl"
            :key="index"
            @click="clickZl(index, item.label)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="kc-list">
        <div>
          <el-radio-group v-model="radio3" size="small" @change="radioFun">
            <el-radio-button label="最新"></el-radio-button>
            <el-radio-button label="最热"></el-radio-button>
            <el-radio-button label="我的" v-if="isLogin"></el-radio-button>
         
          </el-radio-group>
          <el-input
            placeholder="请输入课程、学校或姓名进行搜索"
            v-model="input3"
            class="input-with-select"
            style="width: 50%; float: right; margin-top: -8px"
           
          >
            <el-select v-model="select" slot="prepend" placeholder="请选择" @change="tagFun">
              <el-option label="全部" value=""></el-option>
              <el-option :label="item.title" :value="item.title" v-for="(item,index) in tag" :key="index"></el-option>
              <!-- <el-option label="课程1" value="2"></el-option>
              <el-option label="课程2" value="3"></el-option> -->
            </el-select>
            <el-button slot="append" icon="el-icon-search"  @click="inputFun"></el-button>
          </el-input>
        </div>
        <div style="margin-top:10px;">
          <tabCommDetail
            :newsBookAry="newsBookAry"
            :total="newsBookCount"
            @changePage="changePagecourseBook"
          ></tabCommDetail>
        </div>
      </div>
    </div>
    <footerC></footerC>
  </div>
</template>
<script>
import heade from "@/components/heade.vue";
import footerC from "@/components/footerCommon.vue";
import tabCommDetail from "@/components/tabCommDetail.vue";
export default {
  name: "kc",
  components: { heade, tabCommDetail ,footerC},
  data() {
    return {
      tag:[],
      newsBookAry: [
       

      ],
      newsBookCount: 0,
      radio3: "最新",
      select: "",
      input3: "",
      cur: '全部',
      curZl: "全部",
      fx: [
    
      ],
      zl: [
        ],
      pageKc:1,
      limitK:16,
      curZlName:'',
      curName:'',
      isLogin:false
    };
  },
  mounted(){
    if (
        window.localStorage.getItem("anjing_name_token") &&
        window.localStorage.getItem("anjing_name_token") != ""
      ){
        this.isLogin = true;
  

      }
      else {     this.isLogin = false

      }
      console.log('&&&&&&&&&&&&&&&&&7')
      console.log(this.isLogin)
    
    if( window.localStorage.getItem("anjing_input3_name")){
      this.input3 = window.localStorage.getItem("anjing_input3_name")
    }
    this.inittypelist();
    this.initBook();
    this.taglistFun()
    this.newsBookCount= 30
    this.newsBookAry= [ ]
  },
  methods: {
    // 获取课程
    taglistFun(){
      let data = {
        op: "taglist",
 
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/course.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.tag = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 查询数据
    initBook(){
      debugger;
      console.log('this.curName',this.curName)
      let curName= this.curName;
      if(this.curName == '全部'){
        curName = ''
      }
      let curZlName = this.curZlName
      if(this.curZlName == '全部'){
        curZlName = ''
      }
console.log(curName)
console.log(curZlName)
      let data = {
        op: "search",
        page: this.pageKc,
        limit: this.limitK,
        name:this.input3,
        type: curName,
        stype:curZlName,
        column:this.radio3,
        tag:this.select


        
        
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/course.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.newsBookAry = response.data.data;
              console.log('_this.newsBookAry',_this.newsBookAry)
              _this.newsBookCount = response.data.recordcount;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    tagFun(val){
      console.log(val)
      this.select  =val;
      this.initBook();

    },
    changePagecourseBook(page){
      this.pageKc = page;
      this.initBook();
    },
    // 点击方向
    clickFx(index, item) {
      console.log(item)
      this.cur = index;
      this.curName = item;
      console.log(item);
      this.pageKc= 1;
      if(item=='全部'){
        this.zl= this.fx[0].children;
      }
      else {
        this.zl= this.fx[index].children;
      }
    
      this.initBook()



    },
    clickZl(index, item) {
      console.log(item)
      this.curZl = index;
      this.curZlName = item;
      console.log(item);
      this.initBook()
    },
    radioFun(val){
      console.log(val)
      this.radio3 = val;
      this.initBook()
    },
    // changePage() {},
    inittypelist(){
      let data = {
        op: "typelist",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/course.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.fx = response.data.data
            _this.zl = [];
            // for(let i=0;i<_this.fx.length;i++){
              for(let y=0;y<_this.fx[0].children.length;y++){
                _this.zl.push(_this.fx[0].children[y])
              }
            
            // }
           
            // _this.typeList = response.data.data;
            // _this.initColumn("课程速递", 1);
            // _this.initColumn("最受关注图书榜", 1);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    inputFun(){
      console.log(this.input3);
      console.log(this.select);
      this.initBook()

    },
  },
};
</script>
<style scoped>
.kc {
  width: 1200px;
  margin: 0 auto;
  text-align: left;
}
.tab {
  display: flex;
  margin-top: 30px;
}
.kc-ul {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  color: #000;
}
.kc-ul li {
  /* width:50px; */
  cursor: pointer;
  margin: 0 5px 15px;
  text-align: center;
  font-size: 14px;
  border-radius: 6px;
  padding: 2px 9px;
}
.kc-ul li:hover {
  color: #165dff !important;
}
.kc-ul li.addBg {
  background: #f6f7f9;
  color: #165dff !important;
}
.kc-list {
  margin-top: 20px;
}
</style>
<style>
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>