<template>
  <div class="floor-course">
 <div v-if="newsBookAryCur.length>0">
    <div class="bd floor-side-body" >
      <ul style="margin-left: -12px;overflow: hidden;">
        <li v-for="(item,index) in newsBookAryCur" :key="index" @click="goto(item)">
          <a href="javascript:;">
            <div class="image-ti">
              <img :src="item.imgpath?item.imgpath:require('../images/lo001.jpg')" alt="" />
            </div>
            <p class="slide-title">{{item.title}}</p>
            <p class="slide-body">
              <span class="slide-body-nub">
                <i class="slide-body-nub-icon"></i>
                {{ item.joinnumber }}
              </span>
              <!-- <span class="slide-body-nup">
                <i class="slide-body-nup-icon"></i>
                230
              </span> -->
              <span class="slide-body-price">免费</span>
            </p>
          </a>
        </li>
      </ul>
      <!-- <el-pagination
      hide-on-single-page
      v-if="newsBookAryCur.length!=0"
        small
        layout="prev, pager, next"
        :total="totalCur"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="10"
        style="text-align: right;    margin-top: 9px;"
      >
      </el-pagination> -->

    </div>
    <el-pagination
    hide-on-single-page
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage3"
   
      :page-size="16"
  
      style="text-align: right;"
      :total="total">
    </el-pagination>
    </div>
    <el-empty description="暂无数据" v-else></el-empty>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      currentPage3: 1,
      newsBookAryCur:[],
      totalCur:'',
      input3:''
    };
  },
  props: {
    total: Number,
    newsBookAry: Array,
  },
  watch: {
    newsBookAry(val) {
      console.log(val);
      this.newsBookAryCur = val;
      console.log(this.newsBookAryCur);
    },
    total(val) {
      console.log(val);
      this.totalCur = val;
      console.log(this.totalCur);
    },
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.emit('changePage',val)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      //   this.page = val;
      //   //   this.initColumn();
        this.$emit("changePage", val);
    },
    searchFun() {
  
  window.localStorage.setItem("anjing_input3_name", this.input3);
  if (window.location.href.indexOf("search_list") != -1) {
    this.$emit("searchFun", "0");
  } else {
    this.$router.push({ path: "./search_list" });
  }
},
goto(obj){
  this.$router.push({ path: "./more",query:{seqid:obj.seqid} });
  window.localStorage.setItem('morePath_anjing','./kc')
}
  },
};
</script>
<style  scoped>
@import url(../assets/style.css);
.floor-side-body{
  height:auto;
}
</style></style>